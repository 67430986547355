<template>
  <div>
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header">
        <span class="iconfont icon-Recharge">&nbsp;充值中心</span>
      </div>
      <div class="recharge_user">
        <el-avatar
          :src="userInfo.headPortrait"
          :size="60"
        ></el-avatar>
        <div class="recharge_user_text">
          <p>账户：{{userInfo.nickName?userInfo.nickName:userInfo.mobile}}</p>
          <p style="margin-top:5px">
            账户余额：￥
            <span class="recharge_user_text_moeny">{{userInfo.balance}}</span>
          </p>
        </div>
      </div>

      <div>
        <span class="el-icon-edit-outline recharge_header" style="margin-left:10px">选择充值金额</span>
        <el-row :gutter="10" style="width:1000px; margin-left:20px">
          <el-col
            :xl="4"
            :lg="8"
            :md="10"
            :sm="14"
            :xs="24"
            :offset="0"
            v-for="item in list"
            :key="item.value"
          >
            <div class="recharge_card">
              <div
                :class="isSeletedMoeny==item.value?'recharge_view_seleted':'recharge_view'"
                @click="isSeletedMoeny=item.value"
              >
                <div class="recharge_middle">
                  <span>￥</span>
                  <span class="recharge_middle_one">{{item.lable}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="recharge_selInput">
          <span>其他金额：</span>
          <div id="moenyInput">
            <el-input v-model="money" style="width:200px" size="small"></el-input>
          </div>
        </div>
        <div style="margin:30px 0">
          <span class="el-icon-edit-outline recharge_header" style="margin-left:10px">选择支付方式</span>
          <div class="payment_method">
            <div
              :class="isSeletedPay=='wechat'?'payment_method_view_seleted':'payment_method_view '"
              @click="isSeletedPay='wechat'"
            >
              <i class="iconfont icon-wechat iconts"></i>
              &nbsp;
              <span>微信支付</span>
              <label class="payment_seleted">
                <i class="el-icon-check" style="transform:rotate(-135deg); color:#FFFFFF"></i>
              </label>
            </div>
            <div
              :class="isSeletedPay=='alipay'?'payment_method_view_seleted':'payment_method_view '"
              @click="isSeletedPay='alipay'"
            >
              <i class="iconfont icon-alipay iconts"></i>
              &nbsp;
              <span>支付宝支付</span>
              <label class="payment_seleted">
                <i class="el-icon-check" style="transform:rotate(-135deg); color:#FFFFFF"></i>
              </label>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="payment_view_btn">
          <div style="margin-left:30px">
            <span>支付金额：</span>
            <strong>{{isSeletedMoeny}}</strong>
            <span>&nbsp;&nbsp;元</span>
          </div>
          <div class="payment_view_btn_sub">
            <el-button type="warning" round @click="onSubmitRecharge" style="width:200px">立即充值</el-button>
          </div>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="dialogShowPay" :close-on-click-modal="false" width="20%">
      <span slot="title" class="iconfont icon-alipay" v-if="isSeletedPay=='alipay'">支付宝支付</span>
      <span slot="title" class="iconfont icon-wechat" v-else>微信支付</span>
      <div style="text-align:center;margin-top:20px">
        <h2>请扫描下方二维码进行支付</h2>
      </div>
      <div class="alipay_view_code">
        <iframe
          :srcdoc="alipay_url"
          frameborder="no"
          border="0"
          marginwidth="10"
          marginheight="10"
          scrolling="no"
          width="120"
          height="120"
          style="overflow:hidden"
          v-if="isSeletedPay=='alipay'"
        ></iframe>
        <div id="qrcodeImg" v-else></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { alipayRechargeApi, wechatRechargeApi } from "../../api/data";
import Aes from "../../utils/aes";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      alipay_url: "", //支付宝支付地址表单
      dialogShowPay: false, //支付宝窗口
      money: "",
      isSeletedPay: "wechat",
      isSeletedMoeny: "10",
      timer: "", //定时器
      list: [
        {
          value: "10",
          lable: "10"
        },
        {
          value: "100",
          lable: "100"
        },
        {
          value: "200",
          lable: "200"
        },
        {
          value: "300",
          lable: "300"
        },
        {
          value: "400",
          lable: "400"
        },
        {
          value: "500",
          lable: "500"
        }
      ]
    };
  },
  watch: {
    money(val) {
      this.isSeletedMoeny = val;
    }
  },
  computed: {
    //登录账户信息
    userInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    }
  },
  created() {
  },
  methods: {
    //打开充值
    onSubmitRecharge() {
      if (this.isSeletedPay == "wechat") {
        this.dialogShowPay = true;
        this.$nextTick(() => {
          wechatRechargeApi({
            totalAmount: this.isSeletedMoeny,
            payType: "charge_balance"
          }).then(res => {
            var wx_pay = JSON.parse(res.msg);
            this.qrcode(wx_pay.code_url);
            // this.timer = setInterval(() => {
            //   this.handleQueryPayStatus();
            // }, 1000);
          });
        });
      } else {
        alipayRechargeApi({
          totalAmount: this.isSeletedMoeny,
          payType: "charge_balance"
        }).then(res => {
          this.dialogShowPay = true;
          this.alipay_url = res.msg;
        });
      }
    },
    //微信地址转换为二维码
    qrcode(url) {
      return new QRCode("qrcodeImg", {
        width: 100,
        height: 100,
        text: url,
        colorDark: "#000",
        colorLight: "#fff"
      });
    },
    //查询支付状态
    handleQueryPayStatus() {}
  }
};
</script>
<style lang='scss'>
#moenyInput .el-input__inner:focus {
  border-color: #eabb6a !important;
}
.recharge_user {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .recharge_user_text {
    font-weight: 700;
    font-size: 14px;
    margin-left: 30px;
    .recharge_user_text_moeny {
      font-size: 24px;
      color: #f60;
    }
  }
}

.recharge_header {
  margin-top: 30px;
  font-size: 18px;
  color: #9e9e9e;
}

.recharge_card {
  position: relative;
  margin-top: 20px;
  cursor: pointer;
}
.recharge_view {
  border-radius: 5px;
  background: #ffffff;
  color: #f60;
  border: 1px solid #eee;
}
.recharge_view:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.recharge_view_seleted {
  border-radius: 5px;
  background: #fffcf5;
  color: #f60;
  border: 1px solid #eabb6a;
}
.recharge_middle {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  .recharge_middle_one {
    font-size: 26px;
    font-weight: 700;
  }
}
.recharge_selInput {
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 30px;
}
.payment_method {
  margin-top: 30px;
  display: flex;
  width: 500px;
  justify-content: space-around;
  align-items: center;
  .iconts {
    font-size: 18px;
  }
  .payment_method_view {
    width: 200px;
    border: 1px solid #ebeef5;
    padding: 10px 20px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: #9e9e9e;
    .payment_seleted {
      width: 50px;
      height: 30px;
      background: #909399;
      transform: rotate(135deg);
      position: absolute;
      right: -24px;
      bottom: -14px;
      text-align: center;
      padding-top: 17px;
      box-shadow: 0 1px 1px #ccc;
      opacity: 0;
    }
  }

  .payment_method_view_seleted {
    width: 200px;
    color: #ffffff;
    border: 1px solid #ebeef5;
    padding: 10px 20px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    background: #67c23a;
    cursor: pointer;
    .payment_seleted {
      width: 50px;
      height: 30px;
      background: #909399;
      transform: rotate(135deg);
      position: absolute;
      right: -24px;
      bottom: -14px;
      text-align: center;
      padding-top: 17px;
      box-shadow: 0 1px 1px #67c23a;
      opacity: 1;
    }
  }
}
.payment_view_btn {
  display: flex;
  padding: 50px 0;
  position: relative;
  strong {
    color: #f60;
    font-size: 26px;
  }

  .payment_view_btn_sub {
    margin-left: 200px;
  }
}

.alipay_view_code {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
</style>